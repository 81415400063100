/* General styles */
body, html {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
    scroll-behavior: smooth;
  }
  
  .landing-page {
    width: 100%;
    overflow-x: hidden;
    background-color: #ffffff;
  }
  
/* Hero Section */
.landing-page-hero {
  background: linear-gradient(125deg, rgba(214, 232, 255, 0.48), rgba(255, 255, 255, 1));
  padding: 40px 20px; /* Reduced padding for a more compact look */
}

.hero-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap; /* Ensure responsiveness for smaller screens */
  gap: 25rem;
}

.landing-page-hero-content {
  flex: 1;
  max-width: 600px;
  text-align: left;
}

.landing-page-hero-content h1 {
  font-size: 2.5rem; /* Reduced font size */
  color: #333;
}

.landing-page-hero-content p {
  font-size: 1rem; /* Smaller font size for the paragraph */
  color: #555;
  line-height: 1.5;
}

.landing-page-hero-content hr {
  margin: 10px 0; /* Reduced spacing */
  border: none;
  height: 2px;
  background: #007bff;
}

.landing-page-btn.get-started {
  background-color: #007bff;
  color: white;
  padding: 10px 20px; /* Smaller button size */
  font-size: 1rem;
  text-transform: uppercase;
  border: none;
  border-radius: 6px; /* Slightly less rounded */
  transition: background-color 0.3s ease;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
}

.landing-page-btn.get-started:hover {
  background-color: #0056b3;
}

.landing-page-hero-image {
  flex: 1;
  display: flex;
  justify-content: center;
  max-width: 600px; /* Limit video width */
}

.hero-video {
  width: 50rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-container {
    flex-direction: column;
    gap: 20px;
  }

  .landing-page-hero-content {
    text-align: center;
  }

  .landing-page-hero-content h1 {
    font-size: 2rem;
  }

  .landing-page-hero-content p {
    font-size: 0.9rem;
  }

  .landing-page-btn.get-started {
    font-size: 0.9rem;
    padding: 8px 15px;
  }

  .landing-page-hero-image {
    max-width: 100%;
  }

  .hero-video {
    max-width: 100%;
  }
}


  /* features */
  .landing-page-features {
    background: linear-gradient(125deg, rgba(214, 232, 255, 0.48), rgba(255, 255, 255, 1));
    padding: 50px 40px;
  }
  
  .features-container {
    display: flex;
    flex-direction: column;
  }
  
  .landing-page-feature {
    display: flex;
    align-items: center;
    justify-content: center; /* Center the content */
    flex-wrap: wrap;
    gap: 25rem; /* Reduced gap between text and image */
    padding-bottom: 20px;
  }
    
  .landing-page-feature-image {
    flex: 1;
    display: flex;
    justify-content: center;
    max-width: 600px; /* Limit image width */
  }
  
  .feature-image {
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .landing-page-feature-content {
    flex: 2;
    max-width: 600px;
  }
  
  .landing-page-feature-content h2 {
    font-size: 2.5rem;
    color: #333;
    text-align: left;
  }
  
  .landing-page-feature-content p {
    font-size: 1.2rem;
    color: #555;
    line-height: 1.6;
    text-align: left;
  }
  
  .landing-page-feature-content hr {
    margin: 15px 0;
    border: none;
    height: 2px;
    background: #007bff;
  }
  
  /* feature responsive Design */
  @media (max-width: 768px) {
    .landing-page-feature {
      flex-direction: column;
      gap: 20px;
    }
  
    .landing-page-feature:nth-child(odd) .landing-page-feature-content {
      order: 1;
    }
  
    .landing-page-feature:nth-child(odd) .landing-page-feature-image {
      order: 2;
    }
  
    .landing-page-feature:nth-child(even) .landing-page-feature-content {
      order: 1; /* Ensure the content is above the image */
    }
  
    .landing-page-feature:nth-child(even) .landing-page-feature-image {
      order: 2; /* Ensure the image is below the content */
    }
  
    .landing-page-feature-content,
    .landing-page-feature-image {
      max-width: 100%;
    }
  
    .landing-page-feature-content h2,
    .landing-page-feature-content p {
      text-align: center; /* Center-align text for mobile */
    }
  }

  /* about section */
.landing-page-about {
  background: linear-gradient(125deg, rgba(214, 232, 255, 0.48), rgba(255, 255, 255, 1));
  padding: 50px 40px;
}

.about-container {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the content */
  flex-wrap: wrap;
  gap: 25rem; /* Reduced gap between text and image */
  padding-bottom: 70px;

}

.landing-page-about-image {
  flex: 1;
  display: flex;
  justify-content: center;
  max-width: 600px; /* Limit image width */
}

.about-image {
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.landing-page-about-content {
  flex: 2;
  max-width: 600px;
}

.landing-page-about-content h2 {
  font-size: 2.5rem;
  color: #333;
  text-align: left;
}

.landing-page-about-content p {
  font-size: 1.2rem;
  color: #555;
  line-height: 1.6;
  text-align: left;
}

.landing-page-about-content hr {
  margin: 15px 0;
  border: none;
  height: 2px;
  background: #007bff;
}

/* about responsive design */
@media (max-width: 768px) {
  .about-container {
    flex-direction: column;
    gap: 20px;
  }

  .about-container:nth-child(odd) .landing-page-about-content {
    order: 1;
  }

  .about-container:nth-child(odd) .landing-page-about-image {
    order: 2;
  }

  .about-container:nth-child(even) .landing-page-about-content {
    order: 1; /* Ensure the content is above the image */
  }

  .about-container:nth-child(even) .landing-page-about-image {
    order: 2; /* Ensure the image is below the content */
  }

  .landing-page-about-content,
  .landing-page-about-image {
    max-width: 100%;
  }

  .landing-page-about-content h2,
  .landing-page-about-content p {
    text-align: center; /* Center-align text for mobile */
  }
}

 /* FAQ Section */
.landing-page-faq {
  padding: 50px 10px;
  background-color: #f9f9f9;
  text-align: center;
}

.landing-page-faq h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #333;
}

/* Center FAQ items properly */
.faq-container {
  padding: 50px 180px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  justify-content: center;
}

.faq-item {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: left;
}

.faq-item:hover {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.faq-item.open {
  background-color: #eef7ff;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  padding: 10px;
}

.faq-icon {
  font-size: 1.5rem;
  color: #007bff;
}

/* Ensure the answer is properly styled */
.faq-answer {
  margin-top: 10px;
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
  padding-top: 10px;
  border-top: 1px solid #ddd;
}


/* Contact Section Styles */
.landing-page-contact {
  padding: 50px 20px;
  background: linear-gradient(125deg, rgba(214, 232, 255, 0.48), rgba(255, 255, 255, 1));
  text-align: center;
}

.landing-page-contact h2 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #333;
}

.contact-description {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 30px;
}

.contact-container {
  display: flex;
  justify-content: center;
}

.contact-form {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 400px;
  text-align: left;
}


.contact-form label {
  display: block;
  font-size: 1rem;
  color: #333;
  margin-bottom: 8px;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
}

.contact-form input:focus,
.contact-form textarea:focus {
  border-color: #007bff;
  outline: none;
}

.contact-form .form-row {
  display: flex;
  gap: 30px; /* Ensures spacing between first and last name fields */
  flex-wrap: wrap;
}


.contact-form .form-column {
  flex: 1; /* Ensures both columns take equal width */
  min-width: 150px; /* Prevents fields from becoming too small on smaller screens */
}


.contact-submit-button {
  background-color: #007bff;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  width: 105%
}

.contact-submit-button:hover {
  background-color: #0056b3;
}


