/* General Container */
.manage-teams-container {
  display: grid;
  max-width: 1200px;
  margin-left: 25rem;
  padding: 25px;
  font-family: 'Roboto', sans-serif;
  color: #333;
  min-height: 900px;
  margin-top: 40px;
}

/* Page Title */
.page-title {
  margin-top: 70px;
  text-align: center;
  font-size: 2.5rem;
  color: #444;
  margin-bottom: 30px;
}

/* Teams Section */
.teams-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -150px;
}

/* No Teams Text */
.no-teams-text {
  font-size: 1.2rem;
  color: #777;
  text-align: center;
  margin-top: 20px;
}

/* Grid Layout for Cards */
.teams-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  width: 100%;
}

/* Team Card */
.team-card {
  background: linear-gradient(to bottom right, #ffffff, #f8f9fa);
  border-radius: 12px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.team-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

/* Team Name */
.team-name {
  font-size: 1.5rem;
  color: #222;
  margin-bottom: 10px;
}

.team-description {
  font-size: 1rem;
  color: #666;
  margin-bottom: 20px;
}

/* Button */
.manage-teams-details-button,
.add-member-button {
  height: 40px;
  width: 150px;
}

.manage-teams-details-button,
.add-member-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  margin-top: 10px;
}

.manage-teams-details-button:hover,
.add-member-button:hover {
  background-color: #0056b3;
  box-shadow: 0px 4px 10px rgba(0, 123, 255, 0.5);
}

/* Add Member Form */
.add-member-form {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.add-member-form input,
.add-member-form select {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.submit-button {
  background-color: #28a745;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.submit-button:hover {
  background-color: #218838;
  box-shadow: 0px 4px 10px rgba(40, 167, 69, 0.5);
}

@media (max-width: 768px) {
  /* Adjust container layout for mobile */
  .manage-teams-container {
    margin-left: 0; /* Remove left margin */
    padding: 15px; /* Add uniform padding */
    width: 90%; /* Full width */
    margin-bottom: 50px;
  }

  /* Adjust title alignment */
  .page-title {
    font-size: 1.8rem; /* Slightly smaller title for mobile */
    text-align: center; /* Align to the left */
    padding-left: 15px; /* Add some padding */
    padding-top: 20px;
  }

  /* Adjust the Teams Section */
  .teams-section {
    align-items: flex-start; /* Align content to the left */
    width: 100%; /* Ensure it takes full width */
    margin-top: 50px;
  }

  /* Adjust grid layout for smaller devices */
  .teams-grid {
    grid-template-columns: 1fr; /* Single column layout */
    gap: 15px; /* Reduce gap between cards */
  }

  /* Team Card Adjustments */
  .team-card {
    text-align: left; /* Align content to the left */
    padding: 15px; /* Reduce padding */
  }

  .team-name {
    font-size: 1.2rem; /* Slightly smaller font for mobile */
  }

  .team-description {
    font-size: 0.95rem; /* Adjust font size */
    color: #555; /* Slightly darker text */
  }

  /* Buttons for mobile */
  .manage-teams-details-button,
  .add-member-button {
    width: 100%; /* Full width buttons for mobile */
    padding: 8px 10px; /* Adjust padding */
    font-size: 0.9rem; /* Smaller font size */
  }

  /* Add Member Form Adjustments */
  .add-member-form {
    gap: 8px; /* Reduce gap between form fields */
  }

  .add-member-form input,
  .add-member-form select {
    width: 100%; /* Full width for inputs */
    font-size: 0.9rem; /* Slightly smaller text */
  }

  .submit-button {
    width: 100%; /* Full width for button */
    padding: 8px 10px; /* Adjust padding */
  }
}
