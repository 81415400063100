/* General sidebar styles */
.sidebar {
  width: 250px; /* Fixed width for the sidebar */
  height: 100vh; /* Full height */
  position: fixed; /* Fixed position */
  top: 10px;
  left: 0;
  background-color: #f4f5f7;
  border-right: 1px solid #dfe1e6;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
  padding-top: 80px;
}


.sidebar-header {
  margin-bottom: 30px;
}

.sidebar-header h2 {
  font-size: 1.5rem;
  color: #0747a6;
  font-weight: bold;
}

.sidebar-list {
  list-style: none;
  padding: 0;
  margin: 0;
  flex-grow: 1;
}

.sidebar-item {
  margin-bottom: 15px;
}

.sidebar-item .sidebar-link {
  text-decoration: none;
  color: #505f79;
  font-size: 1rem;
  display: block;
  padding: 10px 15px;
  border-radius: 5px;
  transition: all 0.2s;
}

.sidebar-item .sidebar-link:hover {
  background-color: #e3f2fd;
  color: #0747a6;
}

.sidebar-item.active .sidebar-link {
  background-color: #0747a6;
  color: white;
}

/* Current project section */
.sidebar-project {
  margin-top: auto;
  padding-top: 20px;
  border-top: 1px solid #dfe1e6;
}

.sidebar-project h3 {
  font-size: 1.2rem;
  color: #0747a6;
  margin-bottom: 10px;
}

.sidebar-project .project-name {
  font-weight: bold;
  color: #505f79;
  margin-bottom: 5px;
}

.sidebar-project .project-description {
  font-size: 0.9rem;
  color: #6b778c;
  line-height: 1.4;
}


/* Hide sidebar for mobile view */
@media (max-width: 768px) {
  .sidebar {
    display: none;
  }
}