body, html {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
    scroll-behavior: smooth;
  }

.privacy-policy {
    margin: 0 auto;
    background-color: whitesmoke;
  }
  
  .privacy-policy h1,
  .privacy-policy h2 {
    color: #333;
    padding-left: 20px;

  }
  
  .privacy-policy p,
  .privacy-policy ul {
    padding-left: 20px;
    color: #666;
    line-height: 1.6;
  }
  